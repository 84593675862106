import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '@root/utils/constants/enums';
import { useAuth } from '@root/context/AuthContext/useAuth';
import Loader from '@root/components/Loader';

const AuthPage = (): React.JSX.Element => {
    const { isSignedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSignedIn) {
            navigate(`/${NavigationPaths.CreatePage}`);
        }
    }, [isSignedIn]);

    return <Loader></Loader>;
};

export default AuthPage;
