import React from 'react';
import ReactDOM from 'react-dom/client';
import '@hooks/request/axios';

import reportWebVitals from './reportWebVitals';
import ThemeContextProvider from './context/ThemeContext/ThemeContextProvider';
import { LocalStorageContextProvider } from './context/LocalStorageContext/useLocalStorage';
import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <LocalStorageContextProvider>
        <ThemeContextProvider>
            <App />
        </ThemeContextProvider>
    </LocalStorageContextProvider>
);

reportWebVitals();
