import React, { useState } from 'react';
import ThemeContext from './ThemeContext';
import { useLocalStorage } from '../LocalStorageContext/useLocalStorage';
import { lightTheme } from '../../assets/theme/LightTheme';
import { darkTheme } from '../../assets/theme/DarkTheme';

const ThemeContextProvider = ({ children }) => {
    const { storageTheme, setStorageTheme } = useLocalStorage();

    const [mode, setMode] = useState(storageTheme ? storageTheme === 'light' : false);

    const toggleTheme = () => {
        setMode(!mode);
        setStorageTheme(mode ? 'dark' : 'light');
    };

    return (
        <ThemeContext.Provider
            value={{
                mode,
                toggleTheme,
                theme: mode ? lightTheme : darkTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
