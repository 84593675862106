import React from 'react';
import { Box, styled } from '@mui/material';
import { useCurate } from '@root/hooks/curate/useCurate';
import PreciseModeField from '@root/components/PreciseModeField';
import ToolWithModelsList from './ToolWithModelsList';

export const StyleDriveToolContent = (): React.JSX.Element => {
    const { enablePreciseMode, setEnablePreciseMode } = useCurate();

    return (
        <>
            <ToolWithModelsList />
            <SectionWrapper>
                <PreciseModeField
                    enablePreciseMode={enablePreciseMode}
                    setEnablePreciseMode={setEnablePreciseMode}
                ></PreciseModeField>
            </SectionWrapper>
        </>
    );
};

export default StyleDriveToolContent;

const SectionWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
}));
