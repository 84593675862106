import React from 'react';
import { Box, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { CloseIcon } from '@root/assets/icons/CloseIcon';
import { CurateSceneControlPanelProps } from './CurateSceneControlPanel.types';

const CurateSceneControlPanel = ({ uploadedFileName, onReset, onClose }: CurateSceneControlPanelProps): React.JSX.Element => {
    return (
        <ControlsSection>
            <Tooltip
                placement="bottom-start"
                title={uploadedFileName}
            >
                <ControlLabel>{uploadedFileName}</ControlLabel>
            </Tooltip>
            <ControlLabel
                action
                onClick={onReset}
            >
                {'Reset'}
            </ControlLabel>
            <ActionButton
                disableRipple
                onClick={onClose}
            >
                <CloseIcon />
            </ActionButton>
        </ControlsSection>
    );
};

export default CurateSceneControlPanel;

const ControlLabel = styled(Typography)<{ action?: boolean }>(({ theme, action = false }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.neutrals.inactive1,

    ...(action && {
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.contrastText,
        },
    }),

    ...(!action && {
        maxWidth: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
    width: '32px',
    height: '32px',
    color: theme.palette.neutrals.inactive1,

    '& svg': {
        width: '24px',
        height: '24px',
    },

    '&:hover': {
        background: 'transparent',
        color: theme.palette.primary.contrastText,
    },
}));

const ControlsSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '20px',

    position: 'absolute',
    top: 10,
    maxWidth: '250px',
    height: '32px',
    padding: '0px 2px 0px 10px',

    backgroundColor: theme.palette.primary.grey1,
    borderRadius: '6px',
}));
