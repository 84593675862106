import React from 'react';
import { Box, Button, Modal, styled, Typography } from '@mui/material';
import { ConfirmationModalProps } from './ConfirmationModal.types';

const ConfirmationModal = ({ title, message, active, handleClose, handleConfirm }: ConfirmationModalProps): React.JSX.Element => {
    return (
        <CustomModal open={active}>
            <Wrapper>
                <ModalHeader>{title && <Typography>{title}</Typography>}</ModalHeader>
                <ModalContentRow>
                    <ModalRowInfoSection>
                        <Typography>{message}</Typography>
                    </ModalRowInfoSection>

                    <ModalRowActionSection>
                        <ActionButton
                            variant="contained"
                            onClick={handleConfirm}
                        >
                            {'Confirm'}
                        </ActionButton>
                        <ActionButton
                            color="secondary"
                            variant="outlined"
                            onClick={handleClose}
                        >
                            {'Proceed'}
                        </ActionButton>
                    </ModalRowActionSection>
                </ModalContentRow>
            </Wrapper>
        </CustomModal>
    );
};

export default ConfirmationModal;

const CustomModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    outline: 'none !important',
    boxShadow: `0px 4px 4px 0px ${theme.palette.secondary.grey}`,
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    outline: 'none !important',
    position: 'relative',
    flexDirection: 'column',

    height: '221px',
    width: '478px',
    padding: '48px',
    gap: '32px',

    borderRadius: '16px',
    background: theme.palette.neutrals.main,
}));

const ModalHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiTypography-root': {
        fontFamily: 'Roboto500',
        fontSize: '20px',
        lineHeight: '22px',

        color: theme.palette.primary.contrastText,
    },
}));

const ModalContentRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '32px',
}));

const ModalRowInfoSection = styled(Box)(() => ({
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    position: 'relative',

    gap: '11px',
    height: '100%',

    '& .MuiTypography-root': {
        fontFamily: 'Roboto500',
        fontSize: '15.5px',
        lineHeight: '22.4px',
    },
}));

const ModalRowActionSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',

    height: '100%',
    width: '100%',
    gap: '16px',
}));

const ActionButton = styled(Button)(() => ({
    fontFamily: 'Roboto500',
    fontSize: '14px !important',
    lineHeight: '19.6px !important',

    height: '36px',
    gap: '4px',
}));
