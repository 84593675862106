import React, { useState, ReactNode } from 'react';
import { Box, MenuItem, IconButton, SelectChangeEvent, styled } from '@mui/material';
import { UsersListRowProps } from './UsersListRow.types';
import { EditModeAction, InputCategory, UserRoles } from '@root/utils/constants/enums';
import { RegistrationInfo, UserUpdateInfo } from '@root/types/commonTypes';
import { UsersListDefaults } from '@root/utils/constants';
import { EditIcon } from '@root/assets/icons/EditIcon';
import { SaveIcon } from '@root/assets/icons/SaveIcon';
import { useAdminApi } from '@root/hooks/api/useAdminApi/useAdminApi';
import dayjs from 'dayjs';
import SelectField from '@root/components/SelectField';
import DatePickerField from '@root/components/DatePickerField';
import InputField from '@root/components/InputField';
import { UserInfoDto, UserRegistrationDto } from '@root/types/dto';

const UsersListRow = ({
    userInfo,
    isEditModeActive,
    toggleEditMode,
    editActionType,
    activeRowId,
    setActiveRowId,
    setIsCreateMode,
    isCreateMode,
    setUsersListUpdated,
}: UsersListRowProps): JSX.Element => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [date, setDate] = useState<string | null>(userInfo.expirationDate);
    const [role, setRole] = useState<UserRoles | null>(null);

    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [dateError, setDateError] = useState<boolean>(false);

    const { updateUserInfo, register } = useAdminApi();

    const onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    };

    const onUserRoleChange = (event: SelectChangeEvent<string>, child: ReactNode): void => {
        const newRole = UserRoles[event.target.value as keyof typeof UserRoles];
        setRole(newRole);

        if (newRole === UserRoles.Admin) {
            setDate(null);
        }
    };

    const onEditModeToggle = async (): Promise<void> => {
        let isValid = true;
        if (!isEditModeActive) {
            toggleEditMode(true);
            setActiveRowId(userInfo.id);

            setDefault();
        } else if (isEditModeActive && activeRowId === userInfo.id) {
            if (editActionType === EditModeAction.Create) {
                isValid = validateCreateInfo();
                if (isValid) {
                    const draftUserInfo = saveCreateInfo();
                    createUser(draftUserInfo);
                    setIsCreateMode(false);
                }
            } else if (editActionType === EditModeAction.Update) {
                isValid = validateUpdateInfo();
                if (isValid) {
                    const draftUserInfo = saveUpdateInfo();
                    updateUser(draftUserInfo);
                }
            }

            if (isValid) {
                toggleEditMode(false);
                setActiveRowId('');
            }
        } else if (isEditModeActive && activeRowId !== userInfo.id) {
            if (isCreateMode) {
                isValid = validateCreateInfo();
                if (isValid) {
                    const draftUserInfo = saveCreateInfo();
                    createUser(draftUserInfo);
                    setIsCreateMode(false);
                }
            } else if (editActionType === EditModeAction.Update) {
                isValid = validateUpdateInfo();
                if (isValid) {
                    const draftUserInfo = saveUpdateInfo();
                    updateUser(draftUserInfo);
                }
            }
            if (isValid) {
                setActiveRowId(userInfo.id);
                setDefault();
            }
        }
    };

    const saveUpdateInfo = (): UserUpdateInfo => {
        setPasswordError(false);
        setDateError(false);

        let dateProperty;
        if (date !== dayjs(userInfo.expirationDate).format(UsersListDefaults.DEFAULT_DATE_FORMAT) && date !== userInfo.expirationDate) {
            dateProperty = { expirationDate: date };
        }

        const draftUserInfo: UserUpdateInfo = {
            ...(username && username !== userInfo.name && { name: username }),
            ...(password && { password: password }),
            ...(role && role !== userInfo.roleId && { roleId: role }),
            ...dateProperty,
        };

        return draftUserInfo;
    };

    const saveCreateInfo = (): RegistrationInfo => {
        setPasswordError(false);
        setDateError(false);

        const draftUserInfo: RegistrationInfo = {
            name: username,
            password: password,
            roleId: role || UserRoles.User,
            expirationDate: date || '',
        };
        return draftUserInfo;
    };

    const updateUser = async (draftUserInfo: UserUpdateInfo): Promise<void> => {
        let result;
        if (Object.keys(draftUserInfo).length) {
            result = await updateUserInfo(userInfo.id, draftUserInfo);
        }
        if ((result as UserInfoDto)?.id) {
            setUsersListUpdated(true);
        }
    };

    const createUser = async (draftUserInfo: RegistrationInfo): Promise<void> => {
        setPasswordError(false);
        setDateError(false);
        const result = await register(draftUserInfo);
        if ((result as UserRegistrationDto)?.user?.Id) {
            setUsersListUpdated(true);
        }
    };

    const setDefault = (): void => {
        setUsername(userInfo.name);
        setDate(userInfo.expirationDate);
        setRole(userInfo.roleId);
    };

    const validateUpdateInfo = (): boolean => {
        let isValid = true;

        if (dateError) {
            isValid = false;
        }

        return isValid;
    };

    const validateCreateInfo = (): boolean => {
        let isValid = true;

        if (!password) {
            setPasswordError(true);
            isValid = false;
        }

        if (dateError) {
            isValid = false;
        }
        return isValid;
    };

    return (
        <>
            {isEditModeActive && activeRowId === userInfo.id ? (
                <ItemRow active={+(activeRowId === userInfo.id)}>
                    <UserInfoBlock>{userInfo.name}</UserInfoBlock>
                    <UserInfoBlock>
                        <InputField
                            id="password"
                            name="password"
                            isRequired={true}
                            isMultiline={false}
                            value={password}
                            placeholder={UsersListDefaults.PASSWORD_PLACEHOLDER}
                            category={InputCategory.Secondary}
                            onChange={onPasswordInputChange}
                            error={passwordError}
                        />
                    </UserInfoBlock>
                    <UserInfoBlock>
                        <DatePickerField
                            isDisabled={role === UserRoles.Admin}
                            date={date}
                            setDate={setDate}
                            error={dateError}
                            setDateError={setDateError}
                        />
                    </UserInfoBlock>

                    <UserInfoRoleBlock>
                        <SelectField
                            id="userRole"
                            name="userRole"
                            value={UserRoles[role || UserRoles.User]}
                            onChange={onUserRoleChange}
                            isRequired={true}
                            isDisabled={false}
                            isPrimary={false}
                            content={([UserRoles[UserRoles.Admin], UserRoles[UserRoles.User]] as any).map((item: any) => {
                                return (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        />
                    </UserInfoRoleBlock>

                    <ActionIcons>
                        <ActionButton>
                            <ActionIcon
                                active={+(activeRowId === userInfo.id)}
                                disableRipple
                                onClick={onEditModeToggle}
                            >
                                {activeRowId === userInfo.id ? <SaveIcon /> : <EditIcon />}
                            </ActionIcon>
                        </ActionButton>
                    </ActionIcons>
                </ItemRow>
            ) : (
                <ItemRow active={+(activeRowId === userInfo.id)}>
                    <UserInfoBlock>{userInfo.name}</UserInfoBlock>
                    <UserInfoBlock>{UsersListDefaults.PASSWORD_PLACEHOLDER}</UserInfoBlock>
                    <UserInfoBlock>
                        {userInfo.expirationDate
                            ? dayjs(userInfo.expirationDate).format(UsersListDefaults.DEFAULT_DATE_LABEL_FORMAT)
                            : UsersListDefaults.EMPTY_DATE}
                    </UserInfoBlock>

                    <UserInfoRoleBlock>{UserRoles[userInfo.roleId]}</UserInfoRoleBlock>

                    <ActionIcons>
                        <ActionButton>
                            <ActionIcon
                                active={+(activeRowId === userInfo.id)}
                                disableRipple
                                onClick={onEditModeToggle}
                            >
                                <EditIcon />
                            </ActionIcon>
                        </ActionButton>
                    </ActionIcons>
                </ItemRow>
            )}
        </>
    );
};

export default UsersListRow;

const ItemRow = styled(Box)<{ active: number }>(({ theme, active }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '50px',
    paddingRight: '30px',
    width: '100%',
    height: '55px',
    borderBottom: `${!active ? 1 : 0}px solid ${theme.palette.primary.light}`,
    border: `${active ? 1 : 0}px solid ${theme.palette.primary.main}`,
}));

const UserInfoBlock = styled(Box)(() => ({
    width: '165px',
    marginRight: '160px',
}));

const UserInfoRoleBlock = styled(Box)(() => ({
    width: '100px',
    marginLeft: '25px',
}));

const ActionIcons = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
}));

const ActionButton = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '24px',
    height: '24px',
}));

const ActionIcon = styled(IconButton)<{ active: number }>(({ theme, active }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& svg': {
        color: active ? theme.palette.primary.main : theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.neutrals.contrastText,
        },
    },
}));
