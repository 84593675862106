import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { CuratePageConstants } from '@root/utils/constants';
import { SceneModeOption } from '@root/utils/constants/enums';
import PromptSection from '@root/components/PromptSection';
import ReferenceImageField from '@root/components/ReferenceImageField';
import ToggleField from '@root/components/ToggleField';
import { SceneModeFormProps } from './SceneModeForm.types';

const SceneModeForm = ({
    prompt,
    onPromptChange,
    setReferenceImage,
    setReferenceImageInfluence,
    selectedGenerationTool,
    onSelectedGenerationToolChange,
    referenceImageInfluence,
}: SceneModeFormProps): React.JSX.Element => {
    const [influenceImage, setInfluenceImage] = useState<File | null>(null);
    const [influence, setInfluence] = useState<number>(0);

    const onGenerationToolChange = (option: string): void => {
        onSelectedGenerationToolChange(option as SceneModeOption);
    };

    useEffect(() => {
        if (influence !== referenceImageInfluence) {
            setReferenceImageInfluence(influence);
        }
    }, [referenceImageInfluence]);

    useEffect(() => {
        if (!selectedGenerationTool || !influenceImage) {
            return;
        }

        setReferenceImageInfluence(influence);
        setReferenceImage(influenceImage);
    }, [selectedGenerationTool, influence, influenceImage]);

    useEffect(() => {
        if (!influenceImage) {
            return;
        }

        setReferenceImage(influenceImage);
        if (!influence) {
            setInfluence(CuratePageConstants.DEFAULT_INFLUENCE_SD);
        }
    }, [influenceImage]);

    useEffect(() => {
        setReferenceImageInfluence(influence);
    }, [influence]);

    return (
        <>
            <ControlImageSection>
                <ToggleField
                    selectedOption={selectedGenerationTool}
                    optionsList={Object.values(SceneModeOption)}
                    isRequired={true}
                    setSelectedOption={onGenerationToolChange}
                />
            </ControlImageSection>
            <PromptSection
                prompt={prompt}
                onPromptInputChange={onPromptChange}
            ></PromptSection>
            <ReferenceImageField
                setInfluenceImage={setInfluenceImage}
                influenceImage={influenceImage}
                influence={influence}
                setInfluence={setInfluence}
            ></ReferenceImageField>
        </>
    );
};

export default SceneModeForm;

const ControlImageSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    gap: '13px',
}));
