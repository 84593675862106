import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExpandLeftIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 3.5H20C20.2761 3.5 20.5 3.72386 20.5 4V20C20.5 20.2761 20.2761 20.5 20 20.5H4C3.72386 20.5 3.5 20.2761 3.5 20V4C3.5 3.72386 3.72386 3.5 4 3.5ZM20 2C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H20Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2019 16.3211C15.909 16.614 15.4341 16.614 15.1412 16.3211L8.17893 9.35876L8.17893 14.0836C8.17893 14.4978 7.84315 14.8336 7.42893 14.8336C7.01472 14.8336 6.67893 14.4978 6.67893 14.0836V6.7981L13.9645 6.7981C14.3787 6.7981 14.7145 7.13388 14.7145 7.5481C14.7145 7.96231 14.3787 8.2981 13.9645 8.2981H9.23959L16.2019 15.2604C16.4948 15.5533 16.4948 16.0282 16.2019 16.3211Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
