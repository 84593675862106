import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ScreenshotIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                d="M13.9997 11.9988C13.9997 13.104 13.1049 13.9993 12 13.9993C10.8951 13.9993 10.0003 13.104 10.0003 11.9997C10.0003 10.8954 10.8943 9.99923 12 9.99923C13.1057 9.99923 13.9997 10.8945 13.9997 11.9988ZM9.27686 6.82929C9.53057 6.32156 10.05 6 10.6183 6H13.3817C13.95 6 14.4703 6.32156 14.7231 6.82929L15.3086 8.00046H16.0003C17.1043 8.00046 18 8.89576 18 10.0001V15.0012C18 16.1039 17.1043 17 16.0003 17H7.99971C6.89571 17 6 16.1047 6 14.9995V9.99923C6 8.89491 6.89571 7.99962 7.99971 7.99962H8.69057L9.27686 6.82929ZM15 11.9997C15 10.3436 13.6569 9.00069 12 9.00069C10.3431 9.00069 9 10.3436 9 12.0005C9 13.6574 10.3431 15.0004 12 15.0004C13.6569 15.0004 15 13.6566 15 11.9997Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 3.75C2 2.7835 2.7835 2 3.75 2H8C8.41421 2 8.75 2.33579 8.75 2.75C8.75 3.16421 8.41421 3.5 8 3.5H3.75C3.61193 3.5 3.5 3.61193 3.5 3.75V8C3.5 8.41421 3.16421 8.75 2.75 8.75C2.33579 8.75 2 8.41421 2 8V3.75ZM15.25 2.75C15.25 2.33579 15.5858 2 16 2H20.25C21.2165 2 22 2.7835 22 3.75V8C22 8.41421 21.6642 8.75 21.25 8.75C20.8358 8.75 20.5 8.41421 20.5 8V3.75C20.5 3.61193 20.3881 3.5 20.25 3.5H16C15.5858 3.5 15.25 3.16421 15.25 2.75ZM2.75 15.25C3.16421 15.25 3.5 15.5858 3.5 16V20.25C3.5 20.3881 3.61193 20.5 3.75 20.5H8C8.41421 20.5 8.75 20.8358 8.75 21.25C8.75 21.6642 8.41421 22 8 22H3.75C2.7835 22 2 21.2165 2 20.25V16C2 15.5858 2.33579 15.25 2.75 15.25ZM21.25 15.25C21.6642 15.25 22 15.5858 22 16V20.25C22 21.2165 21.2165 22 20.25 22H16C15.5858 22 15.25 21.6642 15.25 21.25C15.25 20.8358 15.5858 20.5 16 20.5H20.25C20.3881 20.5 20.5 20.3881 20.5 20.25V16C20.5 15.5858 20.8358 15.25 21.25 15.25Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
