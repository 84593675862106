import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: window._env_.REACT_APP_AUTH_URL,
    realm: window._env_.REACT_APP_REALM_NAME,
    clientId: window._env_.REACT_APP_CLIENT_ID,
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
