import React from 'react';
import { useCurate } from '@root/hooks/curate/useCurate';
import { CurateTools } from '@root/utils/constants/enums';
import ToolWithModelsList from './ToolWithModelsList';
import { BrushGenerationSettings } from '../BrushTool';

export const BrushToolContent = (): React.JSX.Element => {
    const {
        isSketchSelected,
        referenceImage,
        setReferenceImage,
        referenceImageInfluence,
        setReferenceImageInfluence,
        sketchInfluence,
        setSketchInfluence,
        convertCanvasImageToSketch,
        currentLayerInfo,
        enablePreciseMode,
        setEnablePreciseMode,
        isFullRerender,
        setIsFullRerender,
        isPartialRerenderEnabled,
    } = useCurate();

    return (
        <>
            <ToolWithModelsList />
            <BrushGenerationSettings
                influenceImage={referenceImage}
                setInfluenceImage={setReferenceImage}
                influence={referenceImageInfluence}
                setInfluence={setReferenceImageInfluence}
                sketchInfluence={sketchInfluence}
                setSketchInfluence={setSketchInfluence}
                onConvertToSketchChecked={convertCanvasImageToSketch}
                isConvertToSketchChecked={isSketchSelected}
                enablePreciseMode={enablePreciseMode}
                setEnablePreciseMode={setEnablePreciseMode}
                isFullRerender={isFullRerender}
                setIsFullRerender={setIsFullRerender}
                isPartialRerenderEnabled={isPartialRerenderEnabled}
                isSketch={currentLayerInfo?.toolId === CurateTools.Sketch}
            ></BrushGenerationSettings>
        </>
    );
};

export default BrushToolContent;
