import React, { useContext, useEffect, useState } from 'react';

import { sortByDateCreated } from '@utils/sortByDateCreated';
import { useAuth } from '../AuthContext/useAuth';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import ProjectsContext from './ProjectsContext';
import LoaderContext from '../LoaderContext/LoaderContext';
import { useProjectApi } from '@hooks/project/ProjectApi';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '@root/utils/constants/enums';
import { useLocalStorage } from '../LocalStorageContext/useLocalStorage';
import { ErrorMessages, ProjectConstants } from '@root/utils/constants';

const ProjectsContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { isSignedIn } = useAuth();
    const { createNotification, notificationMessages } = useNotifications();
    const { getProjects, newProject, deleteProject, likeProject, getProjectById, updateProject } = useProjectApi();
    const { setStorageProjectName } = useLocalStorage();

    const [likedImages, setLikedImages] = useState([]);
    const [projectImagesNumber, setProjectImagesNumber] = useState(0);
    const [projects, setProjects] = useState([]);
    const [activeProject, setActiveProject] = useState(null);

    const handleSetProjects = async () => {
        showLoader();

        const newProjectList = await getProjects();
        if (newProjectList && !newProjectList.errorCode) {
            setProjects(sortByDateCreated(newProjectList));
        }

        hideLoader();
    };

    const updateProjectImagesNumber = async (id) => {
        const result = await getProjectById(id);
        if (result?.Id) {
            setProjectImagesNumber(result.ImagesNumber);
        }
    };

    const createNewProject = async (data) => {
        showLoader();

        const newProjectInfo = await newProject(data);

        if (newProjectInfo && !newProjectInfo.errorCode) {
            setProjectImagesNumber(0);

            await handleSetProjects();
            return newProjectInfo;
        } else if (newProjectInfo?.errorCode) {
            hideLoader();

            createNotification(notificationMessages.project.creating.title, notificationMessages.project.creating.error);
            return newProjectInfo;
        }
    };

    const renameProject = async (data) => {
        const result = await updateProject(data);

        if (result && !result.errorCode) {
            await handleSetProjects();

            if (+data.id === activeProject?.Id) {
                setStorageProjectName(data.name);
            }

            return result;
        } else if (result?.errorCode) {
            return result;
        }
    };

    const deleteProjectById = async (id) => {
        showLoader();
        const result = await deleteProject(id);
        if (result && !result.errorCode) {
            if (id === activeProject?.Id) {
                const defaultProject = projects.find((project) => project.Name === ProjectConstants.DEFAULT_PROJECT_NAME);

                navigate(`/${NavigationPaths.CreatePage}/${defaultProject.Id}`);
            }

            await handleSetProjects();
            createNotification(notificationMessages.project.deleting.title, notificationMessages.project.deleting.success);
        } else {
            createNotification(notificationMessages.project.deleting.title, notificationMessages.project.deleting.error);
        }
    };

    const handleSetLikedImages = async (id) => {
        const defaultProject = projects.find((el) => el.Name === ProjectConstants.DEFAULT_PROJECT_NAME);
        if (!defaultProject && !id) {
            return;
        }
        const projectId = id ? id : defaultProject.Id;
        const newLikedImagesList = await likeProject(projectId);

        if (newLikedImagesList && !newLikedImagesList.errorCode) {
            setLikedImages(newLikedImagesList);
            return newLikedImagesList;
        }
    };

    const setActiveProjectById = (id) => {
        setActiveProject(projects.find((project) => project.Id.toString() === id.toString()));
    };

    const onLikeImage = (image) => {
        setLikedImages([...likedImages, image]);
    };

    const onRemoveLikedImage = (imageId) => {
        setLikedImages((images) => images.filter((image) => image.Id !== imageId));
    };

    const onDeleteImage = (imageId) => {
        const isLikedImage = likedImages.some((image) => image.Id === imageId);
        if (isLikedImage) {
            onRemoveLikedImage(imageId);
        }
    };

    const onDeleteTune = (tuneId) => {
        const hasLikedImage = likedImages.some((image) => image.TuneId === tuneId);

        if (hasLikedImage) {
            setLikedImages((images) => images.filter((image) => image.TuneId !== tuneId));
        }
    };

    const validateProjectName = (name) => {
        let errorMessage = '';

        if (!name || !name.trim()) {
            errorMessage = ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.NAME_IS_EMPTY;
        }

        if (name?.length && name?.length > ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.PROJECT_NAME_MAX_LENGTH) {
            errorMessage = ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.NAME_IS_TOO_LONG;
        }

        return errorMessage;
    };

    useEffect(() => {
        !isSignedIn && setProjects([]);
        isSignedIn && handleSetProjects();
    }, [isSignedIn]);

    useEffect(() => {
        handleSetLikedImages(activeProject?.Id);
    }, [activeProject]);

    return (
        <ProjectsContext.Provider
            value={{
                createNewProject,
                renameProject,
                projects,
                activeProject,
                setActiveProjectById,
                handleSetProjects,
                deleteProjectById,
                handleSetLikedImages,
                likedImages,

                onLikeImage,
                onRemoveLikedImage,
                onDeleteImage,
                onDeleteTune,

                setProjectImagesNumber,
                projectImagesNumber,
                updateProjectImagesNumber,
                validateProjectName,
            }}
        >
            {children}
        </ProjectsContext.Provider>
    );
};

export default ProjectsContextProvider;
