import React, { useContext } from 'react';
import { Box, MenuItem, styled, Typography } from '@mui/material';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import { CurateTools } from '@root/utils/constants/enums';
import { useCurate } from '@root/hooks/curate/useCurate';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import PromptSection from '@root/components/PromptSection';
import SelectField from '@root/components/SelectField';

export const ToolWithModelsList = (): React.JSX.Element => {
    const {
        activeTool,
        modelForGeneration,
        onModelForGenerationInputChange,
        prompt,
        onPromptInputChange,
        seed,
        onSeedInputChange,
        onInputSeed,
        negativePrompt,
        onNegativePromptInputChange,
        enablePreciseMode,
    } = useCurate();

    const { trainedModelsList } = useContext(ModelsContext);
    return (
        <>
            <PromptSection
                prompt={prompt}
                onPromptInputChange={onPromptInputChange}
                negativePrompt={negativePrompt}
                onNegativePromptInputChange={onNegativePromptInputChange}
                seed={seed}
                onSeedInputChange={onSeedInputChange}
                onInputSeed={onInputSeed}
                isNegativePromptRequired={false}
                isPromptRequired={false}
            ></PromptSection>
            <Box>
                <SelectField
                    id="modelForGeneration"
                    name="modelForGeneration"
                    value={modelForGeneration}
                    onChange={onModelForGenerationInputChange}
                    label={'model for generation'}
                    isRequired={true}
                    isDisabled={enablePreciseMode && activeTool === CurateTools.StyleDrive}
                    content={trainedModelsList.map((model: any) => {
                        return (
                            <MenuItem
                                key={model.Id}
                                value={model.Id}
                            >
                                {model.Name.replaceAll('.safetensors', '')}
                            </MenuItem>
                        );
                    })}
                />
                {enablePreciseMode && activeTool === CurateTools.StyleDrive && (
                    <InfoBlock>
                        <InformationIcon />
                        <Typography>{'SDXL model is used for precise mode by default'}</Typography>
                    </InfoBlock>
                )}
            </Box>
        </>
    );
};

export default ToolWithModelsList;

const InfoBlock = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    marginTop: '10px',

    '& svg': {
        width: '17px',
        height: '17px',
        color: theme.palette.primary.main,
    },

    '& p': {
        fontFamily: 'Roboto400',
        fontSize: '12px',
        color: theme.palette.primary.light1,
    },
}));
