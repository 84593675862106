import React from 'react';

export const SliderMarkerSVG = (): JSX.Element => {
    return (
        <svg
            width="154"
            height="77"
            viewBox="0 0 154 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M53 75.5L56.4914 72.5H96.5086L100 75.5"
                stroke="#686868"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M37.5 28L31 21.5V0H122.5V21.5L116 28H37.5Z"
                fill="#2B2B2B"
            />
            <path
                d="M37.5 28L31 21.5V0H0V28H37.5Z"
                fill="url(#paint0_linear_2078_149734)"
            />
            <path
                d="M116 28L122.5 21.5V0H153.5V28H116Z"
                fill="url(#paint1_linear_2078_149734)"
            />
            <path
                d="M31 21.5L37.5 28H116L122.5 21.5"
                stroke="#686868"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M77 62.5L80.4641 68.5H73.5359L77 62.5Z"
                fill="currentColor"
            />
            <rect
                x="76"
                y="32.5"
                width="2"
                height="36"
                fill="currentColor"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2078_149734"
                    x1="0"
                    y1="0"
                    x2="31"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stopColor="#2B2B2B"
                        stopOpacity="0"
                    />
                    <stop
                        offset="1"
                        stopColor="#2B2B2B"
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2078_149734"
                    x1="153.5"
                    y1="0"
                    x2="122.5"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stopColor="#2B2B2B"
                        stopOpacity="0"
                    />
                    <stop
                        offset="1"
                        stopColor="#2B2B2B"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};
