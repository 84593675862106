export enum NavigationPaths {
    Auth = 'auth',
    Projects = 'projects',
    CreatePage = 'create',
    RefinePage = 'refine',
    Models = 'models/model',
    ModelsList = 'models/list',
    Users = 'users',
    Scene = 'scene',
}
