import axios from 'axios';
import { LocalStorageKeys } from '@root/utils/constants';
import { accessTokenUpdate } from './accessTokenUpdate';
import { clearAuthStorage } from '@root/utils/auth/clearAuthStorage';
import keycloak from '@root/context/AuthContext/keycloak-config';

axios.defaults.baseURL = (window as any)._env_.REACT_APP_BASE_API_URL;

if (localStorage[LocalStorageKeys.ACCESS_TOKEN_KEY]) {
    accessTokenUpdate();
}

axios.interceptors.response.use(
    (resp) => resp,
    async (error) => {
        if (error.response && error.response.status === 401 && !error.config.refreshed) {
            error.config.refreshed = true;
            try {
                const refreshed = await keycloak.updateToken();
                if (refreshed && keycloak.token) {
                    localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN_KEY, keycloak.token);
                    accessTokenUpdate();

                    error.config.headers['Authorization'] = `Bearer ${keycloak.token}`;

                    return axios(error.config);
                } else {
                    clearAuthStorage();
                    window.location.href = '/';
                }
            } catch (error) {
                console.error('Error during token refresh.', error);
            }
        }
        return error.response;
    }
);
