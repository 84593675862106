import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { CuratePageConstants } from '@root/utils/constants';
import { SceneShiftToolInfo } from '@root/types/commonTypes';
import { SceneShiftContextItems } from '@root/types/contextTypes';

const SceneShiftContext = createContext<SceneShiftContextItems | null>(null);

export const SceneShiftProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const [isSceneShiftGenerationEnabled, setIsSceneShiftGenerationEnabled] = useState<boolean>(false);

    const [sceneShiftPrompt, setSceneShiftPrompt] = useState(CuratePageConstants.DEFAULT_SCENE_SHIFT_PROMPT);

    const [controlImageSceneShift, setControlImageSceneShift] = useState<File | null>(null);
    const [influenceImageSceneShift, setInfluenceImageSceneShift] = useState<File | null>(null);

    const [influenceSceneShift, setInfluenceSceneShift] = useState<number>(0);

    const [activeImageUrlSceneShift, setActiveImageUrlSceneShift] = useState<string>('');
    const [activeImageLayerIdSceneShift, setActiveImageLayerIdSceneShift] = useState<string | number>('');

    useEffect(() => {
        setIsSceneShiftGenerationEnabled(!!controlImageSceneShift);

        if (influenceImageSceneShift && !influenceSceneShift) {
            setInfluenceSceneShift(CuratePageConstants.DEFAULT_INFLUENCE_SD);
        }
    }, [influenceImageSceneShift, controlImageSceneShift]);

    const onSceneShiftPromptChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSceneShiftPrompt(event.target.value);
    };

    const createSceneShiftConfig = (externalControlImage?: File): SceneShiftToolInfo | null => {
        const controlImage = externalControlImage || controlImageSceneShift;
        if (controlImage) {
            return {
                prompt: sceneShiftPrompt,
                inputImage: controlImage,
                ...(influenceImageSceneShift && { inputReference: influenceImageSceneShift }),
                ...(influenceImageSceneShift && { referenceWeight: influenceSceneShift }),
            };
        }

        return null;
    };

    const clearSceneShiftToolInfo = (): void => {
        setControlImageSceneShift(null);
        setInfluenceImageSceneShift(null);
        setActiveImageLayerIdSceneShift('');
        setActiveImageUrlSceneShift('');

        setInfluenceSceneShift(0);
    };

    const value: SceneShiftContextItems = {
        sceneShiftPrompt,
        onSceneShiftPromptChange,

        activeImageUrlSceneShift,
        setActiveImageUrlSceneShift,

        controlImageSceneShift,
        setControlImageSceneShift,

        influenceImageSceneShift,
        setInfluenceImageSceneShift,

        influenceSceneShift,
        setInfluenceSceneShift,

        activeImageLayerIdSceneShift,
        setActiveImageLayerIdSceneShift,

        isSceneShiftGenerationEnabled,
        createSceneShiftConfig,
        clearSceneShiftToolInfo,
    } as SceneShiftContextItems;

    return <SceneShiftContext.Provider value={value}>{children}</SceneShiftContext.Provider>;
};

export const useSceneShift = (): SceneShiftContextItems => {
    const context = useContext(SceneShiftContext);

    if (context === null) {
        throw new Error('useSceneShift cannot be used without its provider');
    }

    return context;
};
