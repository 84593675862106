import React, { useContext, useEffect, useState } from 'react';

import GenerateContext from './GenerateContext';
import ModelsContext from '../ModelsContext/ModelsContext';
import { useAuth } from '../AuthContext/useAuth';
import { getImageAspectRatio } from '@root/utils/getImageAspectRatio';
import { CameraViewConstants, CommonConstants } from '@root/utils/constants';
import { ControlImageOption, ModelForGenerationType, SupportedAspectRatios } from '@root/utils/constants/enums';

const DEFAULT_CONTROL_WEIGHT = 50;

const GenerateContextProvider = ({ children }) => {
    const { trainedModelsList } = useContext(ModelsContext);
    const { isSignedIn } = useAuth();

    const [modelForGeneration, setModelForGeneration] = useState('');
    const [aspectRatio, setAspectRatio] = useState(2);
    const [isAspectRatioChangedBySketch, setIsAspectRatioChangedBySketch] = useState(false);
    const [isAspectRatioChangedByControlImage, setIsAspectRatioChangedByControlImage] = useState(false);
    const [numberOfImages, setNumberOfImages] = useState(4);
    const [imageTitle, setImageTitle] = useState('');
    const [prompt, setPrompt] = useState(CommonConstants.DEFAULT_PROMPT);
    const [negativePrompt, setNegativePrompt] = useState(CommonConstants.DEFAULT_NEGATIVE_PROMPT);
    const [seed, setSeed] = useState(CommonConstants.DEFAULT_SEED_VALUE);
    const [enableHighResolution, setEnableHighResolution] = useState(false);
    const [enableDepthControl, setEnableDepthControl] = useState(false);
    const [enableSketchControl, setEnableSketchControl] = useState(false);
    const [controlDepthImage, setControlDepthImage] = useState('');
    const [controlSketchImage, setControlSketchImage] = useState('');
    const [controlDepthWeight, setControlDepthWeight] = useState(DEFAULT_CONTROL_WEIGHT);
    const [controlSketchWeight, setControlSketchWeight] = useState(DEFAULT_CONTROL_WEIGHT);
    const [enableAdvanced, setEnableAdvanced] = useState(false);
    const [enableCameraView, setEnableCameraView] = useState(false);
    const [cameraView, setCameraView] = useState();
    const [selectedModelType, setSelectedModelType] = useState(ModelForGenerationType.CUSTOM);
    const [controlImageOption, setControlImageOption] = useState(null);

    const onPromptInputChange = (event) => {
        setPrompt(event.target.value);
    };

    const onNegativePromptInputChange = (event) => {
        setNegativePrompt(event.target.value);
    };
    const onAspectRatioValueChange = (index) => {
        setAspectRatio(index);

        setIsAspectRatioChangedByControlImage(false);
        setIsAspectRatioChangedBySketch(false);
    };

    const onNumberOfImagesInputChange = (value) => {
        setNumberOfImages(value);
    };

    const onEnableAdvancedInputChange = () => {
        setEnableAdvanced(!enableAdvanced);
    };

    const onModelForGenerationInputChange = (event) => {
        setModelForGeneration(event.target.value);
    };

    const onSeedInputChange = (event) => {
        if (+event.target.value === -1) {
            setSeed(CommonConstants.DEFAULT_SEED_VALUE);
        } else if (event.target.value === CommonConstants.DEFAULT_SEED_VALUE) {
            setSeed('');
        } else if (!Number.isInteger(+event.target.value)) {
            setSeed(CommonConstants.DEFAULT_SEED_VALUE);
        } else {
            setSeed(+event.target.value);
        }
    };

    const onInputSeed = (e) => {
        if (e.key === 'Backspace' && !Number.isInteger(+e.targetValue)) {
            setSeed('');
        }
    };

    const onEnableHighResolutionInputChange = (value) => {
        setEnableHighResolution(value);
    };

    const onControlDepthImageChange = (value) => {
        setControlDepthImage(value);

        setControlImageAspectRatio(value);
        setIsAspectRatioChangedByControlImage(true);
        setIsAspectRatioChangedBySketch(false);
    };

    const onControlSketchImageChange = (value) => {
        setControlSketchImage(value);

        setControlImageAspectRatio(value);
        setIsAspectRatioChangedBySketch(true);
        setIsAspectRatioChangedByControlImage(false);
    };

    const setControlImageAspectRatio = async (controlImage) => {
        let controlImageFile;

        if (controlImage.src) {
            controlImageFile = await fetch(controlImage.src).then((res) => res.blob());
        } else {
            controlImageFile = controlImage;
        }

        const controlImageUrl = controlImageFile ? URL.createObjectURL(controlImageFile) : '';
        const activeControlImage = new Image();
        activeControlImage.src = controlImageUrl;

        if (activeControlImage.src) {
            activeControlImage.onload = () => {
                const suggestedAspectRatio = getImageAspectRatio(activeControlImage.width, activeControlImage.height);

                if (suggestedAspectRatio) {
                    const aspectRatioLabels = Object.values(SupportedAspectRatios).map((value) => value?.aspectRatioLabel);
                    const aspectRatioIndex = aspectRatioLabels.indexOf(suggestedAspectRatio) + 1;
                    setAspectRatio(aspectRatioIndex);
                }
            };
        }
    };

    const hideDepthControl = () => {
        setEnableDepthControl(false);
        setControlDepthImage('');
    };

    const showDepthControl = () => {
        setEnableDepthControl(true);
        setControlDepthWeight(DEFAULT_CONTROL_WEIGHT);
    };

    const hideSketchControl = () => {
        setEnableSketchControl(false);
        setControlSketchImage('');
    };

    const showSketchControl = () => {
        setEnableSketchControl(true);
    };

    const hideCameraView = () => {
        setEnableCameraView(false);
    };

    const showCameraView = () => {
        setEnableCameraView(true);
        setControlDepthWeight(CameraViewConstants.DEFAULT_CAMERA_VIEW_WEIGHT);
    };

    const handleSetEnableControlImageOption = (option, enable) => {
        switch (option) {
            case ControlImageOption.PHOTO:
                if (enable) {
                    showDepthControl();
                    hideSketchControl();
                    hideCameraView();
                } else {
                    hideDepthControl();
                }
                break;
            case ControlImageOption.SKETCH:
                if (enable) {
                    showSketchControl();
                    hideDepthControl();
                    hideCameraView();
                } else {
                    hideSketchControl();
                }
                break;
            case ControlImageOption.CAMERA:
                if (enable) {
                    showCameraView();
                    hideDepthControl();
                    hideSketchControl();
                } else {
                    hideCameraView();
                }
                break;
            default:
                break;
        }
    };

    const changeControlImageOption = (option) => {
        setControlImageOption(option);

        if (!option) {
            Object.values(ControlImageOption).forEach((controlImageOption) => {
                handleSetEnableControlImageOption(controlImageOption, false);
            });
            return;
        }

        handleSetEnableControlImageOption(option, true);
    };

    const getFluxTuneData = (id) => {
        const jsonData = {
            projectId: +id,
            tuneTitle: imageTitle,
            prompt,
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : seed,
            aspectRatioIndex: aspectRatio,
            width: null,
            height: null,
        };

        const formData = new FormData();

        formData.append('json', JSON.stringify(jsonData));

        return formData;
    };

    const getDefaultTuneData = async (id) => {
        const jsonData = {
            projectId: +id,
            modelId: modelForGeneration,
            tuneTitle: imageTitle,
            prompt,
            negativePrompt: negativePrompt,
            numberOfImages,
            steps: 30,
            cfgScale: 7,
            sampler: 'Euler',
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : seed,
            aspectRatioIndex: aspectRatio,
            enableHighResolution,
            enableDepthControl: enableDepthControl || enableCameraView,
            controlDepthWeight:
                enableDepthControl || enableCameraView ? controlDepthWeight : CameraViewConstants.DEFAULT_CAMERA_VIEW_WEIGHT,
            enableSketchControl,
            controlSketchWeight: controlSketchWeight,
            width: null,
            height: null,
        };

        const formData = new FormData();
        let depthImage;

        if (enableCameraView) {
            depthImage = await fetch(cameraView.saveCanvasAsImage()).then((res) => res.blob());
        } else {
            depthImage = controlDepthImage;
        }

        formData.append('controlDepthImage', enableDepthControl || enableCameraView ? depthImage : '');
        formData.append('controlSketchImage', enableSketchControl ? controlSketchImage : '');
        formData.append('json', JSON.stringify(jsonData));

        return formData;
    };

    const getNewTuneData = async (id) => {
        if (selectedModelType === ModelForGenerationType.FLUX) {
            return getFluxTuneData(id);
        }

        const defaultData = await getDefaultTuneData(id);
        return defaultData;
    };

    const setDefaultSettings = () => {
        setModelForGeneration(getDefaultModel()?.Id || '');
        setAspectRatio(2);
        setNumberOfImages(4);
        setPrompt(CommonConstants.DEFAULT_PROMPT);
        setNegativePrompt(CommonConstants.DEFAULT_NEGATIVE_PROMPT);
        setSeed(CommonConstants.DEFAULT_SEED_VALUE);
        setEnableHighResolution(false);
        setEnableDepthControl(false);
        setControlDepthWeight(DEFAULT_CONTROL_WEIGHT);
        setControlDepthImage('');
        setEnableAdvanced(false);
    };

    const getDefaultModel = () => {
        return trainedModelsList.find((el) => el.Name.includes(CommonConstants.DEFAULT_MODEL_NAME));
    };

    useEffect(() => {
        if (!isSignedIn) {
            setDefaultSettings();
        }
    }, [isSignedIn]);

    useEffect(() => {
        const defaultModel = getDefaultModel();
        defaultModel && setModelForGeneration(defaultModel.Id);
    }, [trainedModelsList]);

    return (
        <GenerateContext.Provider
            value={{
                modelForGeneration,
                aspectRatio,
                isAspectRatioChangedBySketch,
                isAspectRatioChangedByControlImage,
                numberOfImages,
                imageTitle,
                prompt,
                setPrompt,
                negativePrompt,
                seed,
                enableHighResolution,
                controlDepthImage,
                controlSketchImage,
                controlDepthWeight,
                controlSketchWeight,
                enableAdvanced,
                enableCameraView,
                cameraView,
                setCameraView,

                onPromptInputChange,
                onNegativePromptInputChange,
                onAspectRatioValueChange,
                onNumberOfImagesInputChange,
                onEnableAdvancedInputChange,
                onModelForGenerationInputChange,
                onSeedInputChange,
                onInputSeed,
                onEnableHighResolutionInputChange,
                onControlDepthImageChange,
                onControlSketchImageChange,
                setControlDepthWeight,
                setControlSketchWeight,
                getNewTuneData,

                setDefaultSettings,
                selectedModelType,
                setSelectedModelType,

                changeControlImageOption,
                controlImageOption,
            }}
        >
            {children}
        </GenerateContext.Provider>
    );
};

export default GenerateContextProvider;
