import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { StyleDriveContextItems } from '@root/types/contextTypes';
import { StyleDriveToolInfo } from '@root/types/commonTypes';
import { CuratePageConstants } from '@root/utils/constants';

const StyleDriveContext = createContext<StyleDriveContextItems | null>(null);

export const StyleDriveProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const [isReadyForGeneration, setIsReadyForGeneration] = useState<boolean>(false);

    const [controlImageSD, setControlImageSD] = useState<File | null>(null);
    const [firstInfluenceImageSD, setFirstInfluenceImageSD] = useState<File | null>(null);
    const [secondInfluenceImageSD, setSecondInfluenceImageSD] = useState<File | null>(null);

    const [controlInfluenceSD, setControlInfluenceSD] = useState<number>(0);
    const [firstInfluenceSD, setFirstInfluenceSD] = useState<number>(0);
    const [secondInfluenceSD, setSecondInfluenceSD] = useState<number>(0);

    const [activeImageUrlSD, setActiveImageUrlSD] = useState<string>('');
    const [activeImageLayerIdSD, setActiveImageLayerIdSD] = useState<string | number>('');

    useEffect(() => {
        setIsReadyForGeneration(!!controlImageSD && (!!firstInfluenceImageSD || !!secondInfluenceImageSD));

        if (controlImageSD) {
            setControlInfluenceSD(CuratePageConstants.DEFAULT_CONTROL_INFLUENCE_SD);
        }

        if (firstInfluenceImageSD && !firstInfluenceSD) {
            setFirstInfluenceSD(CuratePageConstants.DEFAULT_INFLUENCE_SD);
        }

        if (secondInfluenceImageSD && !secondInfluenceSD) {
            setSecondInfluenceSD(CuratePageConstants.DEFAULT_INFLUENCE_SD);
        }
    }, [firstInfluenceImageSD, secondInfluenceImageSD, controlImageSD]);

    const createStyleDriveConfig = (externalControlImage?: File): StyleDriveToolInfo | null => {
        const controlImage = externalControlImage || controlImageSD;
        if (controlImage) {
            return {
                controlImage: controlImage,
                controlInfluence: controlInfluenceSD || CuratePageConstants.DEFAULT_CONTROL_INFLUENCE_SD,
                setCameraView: true,
                ...(firstInfluenceImageSD && { firstInfluenceImage: firstInfluenceImageSD }),
                ...(firstInfluenceImageSD && { firstInfluence: firstInfluenceSD }),
                ...(secondInfluenceImageSD && { secondInfluenceImage: secondInfluenceImageSD }),
                ...(secondInfluenceImageSD && { secondInfluence: secondInfluenceSD }),
            };
        }

        return null;
    };

    const clearStyleDriveToolInfo = (): void => {
        setControlImageSD(null);
        setFirstInfluenceImageSD(null);
        setSecondInfluenceImageSD(null);
        setActiveImageLayerIdSD('');
        setActiveImageUrlSD('');

        setControlInfluenceSD(0);
        setFirstInfluenceSD(0);
        setSecondInfluenceSD(0);
    };

    const value: StyleDriveContextItems = {
        isReadyForGeneration,

        activeImageUrlSD,
        setActiveImageUrlSD,
        controlImageSD,
        setControlImageSD,
        firstInfluenceImageSD,
        setFirstInfluenceImageSD,
        secondInfluenceImageSD,
        setSecondInfluenceImageSD,

        controlInfluenceSD,
        setControlInfluenceSD,
        firstInfluenceSD,
        setFirstInfluenceSD,
        secondInfluenceSD,
        setSecondInfluenceSD,

        activeImageLayerIdSD,
        setActiveImageLayerIdSD,

        createStyleDriveConfig,
        clearStyleDriveToolInfo,
    } as StyleDriveContextItems;

    return <StyleDriveContext.Provider value={value}>{children}</StyleDriveContext.Provider>;
};

export const useStyleDrive = (): StyleDriveContextItems => {
    const context = useContext(StyleDriveContext);

    if (context === null) {
        throw new Error('useStyleDrive cannot be used without its provider');
    }

    return context;
};
