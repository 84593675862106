import React, { useState } from 'react';
import { Box, styled, ToggleButtonGroup } from '@mui/material';
import { InputCategory } from '@root/utils/constants/enums';
import ToggleField from '@root/components/ToggleField';
import LensSlider from './LensSlider';
import RollSlider from './RollSlider';
import { SceneModeFooterProps } from './SceneModeFooter.types';

enum ButtonsType {
    LENS = 'lens',
    ROLL = 'roll',
}

const SceneModeFooter = ({ isDisabled }: SceneModeFooterProps): React.JSX.Element => {
    const [selectedButton, setSelectedButton] = useState<string>(ButtonsType.LENS);

    const handleButtons = (buttonValue: string): void => {
        setSelectedButton(buttonValue);
    };

    return (
        <Container>
            <ControlsContainer>
                {selectedButton === ButtonsType.LENS ? <LensSlider isDisabled={isDisabled} /> : <RollSlider isDisabled={isDisabled} />}
            </ControlsContainer>
            <ControlsContainer>
                <CustomToggleContainer>
                    <ToggleField
                        disabled={isDisabled}
                        category={InputCategory.Secondary}
                        selectedOption={selectedButton}
                        optionsList={Object.values(ButtonsType)}
                        isRequired={true}
                        setSelectedOption={handleButtons}
                    />
                </CustomToggleContainer>
            </ControlsContainer>
        </Container>
    );
};

export default SceneModeFooter;

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    height: '174px',
}));

const ControlsContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    height: '75px',
    width: '100%',

    ':last-child': {
        marginTop: '20px',
    },
}));

const CustomToggleContainer = styled(ToggleButtonGroup)(() => ({
    width: '250px',
}));
