import React from 'react';
import { Box, styled } from '@mui/material';
import { useCurate } from '@root/hooks/curate/useCurate';
import InfluenceSlider from '@root/components/InfluenceSlider';
import PreciseModeField from '@root/components/PreciseModeField';
import ReferenceImageField from '@root/components/ReferenceImageField';
import ToolWithModelsList from './ToolWithModelsList';

export const LassoToolContent = (): React.JSX.Element => {
    const {
        referenceImage,
        setReferenceImage,
        referenceImageInfluence,
        setReferenceImageInfluence,
        enablePreciseMode,
        setEnablePreciseMode,
        denoiseWeight,
        setDenoiseWeight,
    } = useCurate();

    return (
        <>
            <ToolWithModelsList />
            <SectionWrapper>
                <ReferenceImageField
                    setInfluenceImage={setReferenceImage}
                    influenceImage={referenceImage}
                    influence={referenceImageInfluence}
                    setInfluence={setReferenceImageInfluence}
                ></ReferenceImageField>
                <PreciseModeField
                    enablePreciseMode={enablePreciseMode}
                    setEnablePreciseMode={setEnablePreciseMode}
                ></PreciseModeField>
                <InfluenceSlider
                    id={'denoiseweight'}
                    value={denoiseWeight}
                    OnChange={setDenoiseWeight}
                    label={'processing intensity'}
                    step={1}
                    range={[0, 100]}
                    endAdornment={'%'}
                    isReversed={false}
                ></InfluenceSlider>
            </SectionWrapper>
        </>
    );
};

export default LassoToolContent;

const SectionWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
}));
