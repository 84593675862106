import React, { useMemo, useState } from 'react';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Box, Divider, styled } from '@mui/material';
import type { Active, Over } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import LayerPreview from '../layersPreviewComponents/LayerPreview';
import { ImageGenerationStatus } from '@root/utils/constants/enums';
import SortableListRowOverlay from '@root/components/SortableListHelpers/SortableListRowOverlay';
import SortableListRow from '@root/components/SortableListHelpers/SortableListRow';
import { CurateLayersListProps } from './CurateLayersList.types';

const CurateLayersList = ({
    layers,
    lastVisibleLayerId,
    deleteLayer,
    setFocus,
    selectLayerByIndex,
    getBlendedImage,
    showMetadataDialog,
    renameLayer,
    onChange,
    isSelectEnabled = true,
}: CurateLayersListProps): React.JSX.Element => {
    const [active, setActive] = useState<Active | null>(null);

    const activeLayer = useMemo(() => layers.find((layer) => layer.id === active?.id), [active, layers]);

    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }));

    const dragEndHandler = (active: Active, over: Over | null): void => {
        if (over && active.id !== over?.id) {
            const activeIndex = layers.findIndex(({ id }) => id === active.id);
            const overIndex = layers.findIndex(({ id }) => id === over.id);

            const updatedLayers = arrayMove(layers, activeIndex, overIndex);
            const updatedLayersIds = updatedLayers.map((layer) => layer.id);

            onChange(updatedLayersIds);
        }
        setActive(null);
    };

    return (
        <Wrapper>
            <DndContext
                sensors={sensors}
                onDragStart={({ active }) => {
                    setActive(active);
                }}
                onDragEnd={({ active, over }) => {
                    dragEndHandler(active, over);
                }}
                onDragCancel={() => {
                    setActive(null);
                }}
            >
                <SortableContext items={layers}>
                    {layers
                        .concat()
                        .reverse()
                        .map(
                            (layer, i) =>
                                layer.layerStatus === ImageGenerationStatus.READY && (
                                    <SortableListRow
                                        id={layer.id}
                                        key={layer.id}
                                    >
                                        <div>
                                            <LayerPreview
                                                isSelectEnabled={isSelectEnabled}
                                                layer={layer}
                                                orderIndex={layer.orderIndex}
                                                renameLayer={renameLayer}
                                                isSelected={layer.id === lastVisibleLayerId}
                                                setFocus={setFocus}
                                                selectLayerByIndex={selectLayerByIndex}
                                                deleteLayer={deleteLayer}
                                                showMetadataDialog={showMetadataDialog}
                                                getBlendedImage={getBlendedImage}
                                            />
                                            {i !== layers.length - 1 && <Divider />}
                                        </div>
                                    </SortableListRow>
                                )
                        )}
                </SortableContext>
                <SortableListRowOverlay>
                    {activeLayer ? (
                        <SortableListRow id={activeLayer.id}>
                            <LayerPreview
                                isSelectEnabled={isSelectEnabled}
                                layer={activeLayer}
                                isSelected={activeLayer.id === lastVisibleLayerId}
                                setFocus={setFocus}
                                selectLayerByIndex={selectLayerByIndex}
                                renameLayer={renameLayer}
                                deleteLayer={deleteLayer}
                                showMetadataDialog={showMetadataDialog}
                                getBlendedImage={getBlendedImage}
                            />
                        </SortableListRow>
                    ) : null}
                </SortableListRowOverlay>
            </DndContext>
        </Wrapper>
    );
};

export default React.memo(CurateLayersList);

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
